export default [
  {
    key: 'code',
    label: 'field.companyCode',
    type: 'text',
    operator: '=',
  },
  {
    key: 'name',
    label: 'field.companyName',
    type: 'text',
    operator: '%',
  },
];
